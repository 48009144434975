import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.com/google-cloud/cloud-native-advantages-of-moving-your-etl-process-to-cloud-run-39bf0e6cdee3"
        }}>{`Cloud-Native Advantages of Moving Your ETL Process to Cloud Run`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.com/google-cloud/develop-your-cloud-tasks-pipeline-locally-with-ngrok-5bee3693600f"
        }}>{`Develop your Cloud Tasks pipeline locally with ngrok!`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cloud.google.com/blog/products/application-development/app-engine-java-11-is-ga-deploy-a-jar-scale-it-all-fully-managed"
        }}>{`App Engine Java 11 is GA—deploy a JAR, scale it, all fully managed`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      